@import '~antd/dist/antd.less';

@primary-color: #039EFF;
// @secondary-color: #B2A8FB;
@processing-color: @purple-6;

@menu-dark-bg: transparent;
@menu-dark-submenu-bg: transparent;

html, body, #root {
  height: 100%;
  width: 100%;
  background-color: #f0f2f5;
}

.ant-layout, main {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


// // Drawer
// @layout-header-background: linear-gradient(to bottom, #362D7A, #201A47);

// // Background
// @layout-body-background: #F8F7FF;

// // Components
// @component-background: #FFF;

// .ant-layout-header, .ant-layout {
//   background: red;
// }

// .ant-layout-content {
//   background: #F8F7FF;
//   border-radius: 12px 0 0 0;
//   padding: 0px 16px;
//   box-shadow: inset 0 4px 8px -4px #f0f1f2;
// }

// .ant-layout-sider-trigger {
//   background: #14112E;
// }

// .ant-card, .ant-card-bordered {
//   border-radius: 12px;
// }

// .ant-btn {
//   border-radius: 8px; 
// }

// .ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
//   color: white
// }